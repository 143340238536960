import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointUp } from '@fortawesome/free-regular-svg-icons';
import SEO from "../components/seo";
import PeopleContent from '../components/peopleContent';
import { connect } from "react-redux"
import PageWrapper from "../components/pageWrapper";
import 'intl/locale-data/jsonp/sv';
import i18nMessages from '../i18n/sv.json';
import { FormattedMessage, injectIntl, } from 'react-intl';
import Button from 'react-bootstrap/Button';
import { navigate } from 'gatsby';


class People extends React.Component {

 	render() {
 		if (this.props.initialLoader) {
 			return (
 				<div className="chatPageWrapper page">
					<div className='dcLoader medium preLoader' />
 				</div>
 			);
 		} else {
 			if (this.props.loggedIn && this.props.plusMember) {
	        	return (
					<PageWrapper location={this.props.location} lang="sv" i18nMessages={i18nMessages}>
						<PeopleContent langUrl="/sv" />
					</PageWrapper>
				);

	        } else {
	        	return(
					<PageWrapper location={this.props.location} lang="sv" i18nMessages={i18nMessages}>
						<div className="chatPageWrapper page">
							<SEO 
				                title="Personer"
					            pagePath="/people"
			            	/>
							<div className="errorText"><FormattedMessage id="plusShowPeople" /> <br /><br />
								<Button size="sm" onClick={ () => navigate('/account') } variant="outline-info">
									<FormattedMessage id="goTo" /> <FormattedMessage id="account" />
								</Button>
							</div>
						
						</div>
					</PageWrapper>
				);
	        }
 		}
       
		
	}
}

function mapStateToProps(state) {
	const { loggedIn, totalUnread, initialLoader, plusMember } = state
	return { loggedIn: loggedIn,
			 totalUnread: totalUnread,
			 initialLoader: initialLoader,
			 plusMember: plusMember
			}
}


export default injectIntl(connect(
	mapStateToProps
)(People));